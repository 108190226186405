@font-face {
font-family: 'polySans';
src: url(/_next/static/media/1b23d0c235e04d7e-s.p.otf) format('opentype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: 'polySans';
src: url(/_next/static/media/fce67eba4faaa94f-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'polySans';
src: url(/_next/static/media/c3ef2d77b02a76e5-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'polySans';
src: url(/_next/static/media/3259ad9085444cf4-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'polySans Fallback';src: local("Arial");ascent-override: 76.11%;descent-override: 19.79%;line-gap-override: 0.00%;size-adjust: 105.11%
}.__className_c55d03 {font-family: 'polySans', 'polySans Fallback'
}

